import { Box, Center, Container, HStack, List, ListIcon, ListItem, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { Background } from 'components/background/Background';
import Footer from 'components/footer';
import LandingFeature from 'components/landingpage/features';
import LandingPageHero from 'components/landingpage/Hero';
import TimeSteps from 'components/landingpage/TimeSteps';
import BugReportsSection from 'components/landingpage/BugReportsSection';
import FeedbackSection from 'components/landingpage/FeedbackSection';
import CommunityAtScale from 'components/landingpage/CommunityAtScale';
import MoreFeatures from 'components/landingpage/MoreFeatures';
import StudioReviews from 'components/landingpage/StudioReviews';
import BottomCTA from 'components/landingpage/BottomCTA';
import { NextSeo } from 'next-seo';
import TrustedBy from 'components/trusted-by/trusted-by';
import { LuClock12, LuClock3, LuClock7, LuClock9 } from "react-icons/lu";
import { CSSProperties } from 'react';

const types = [
  {
    title: 'Community Analytics',
    copy: 'Gain understanding across bug reports, feedback and community generated content to extrapolate actionable insights that directly impact player LTV, engagement and prioritization of development efforts.',
    image: 'CommunityAnalytics.png',
    bullets: [],
    status: 'In Development',
  },
  {
    title: 'AI-Guided Bug Reporting Workflow',
    copy: 'Say goodbye to spreadsheets, complex forms, incomplete reports and copying and pasting. Leverage the power of AI to streamline and centralize your bug reporting workflows.',
    // image: 'bugNew.png',
    animBlock: 'aibug',
    // bullets: ['Where your users already are'],
    status: 'Released',
  },
  {
    title: 'AI-Guided Feedback Workflow',
    copy: 'Understand the "why" behind community suggestions effortlessly. Eliminate back-and-forth communication. Harness the power of AI to streamline and centralize your feedback workflows.',
    image: 'Feedback.png',
    bullets: [],
    status: 'In Development',
  },
  {
    animBlock: 'test',
    title: 'Direct Project Management Integration',
    copy: 'Understand the "why" behind community suggestions effortlessly. Eliminate back-and-forth communication. Harness the power of AI to streamline and centralize your feedback workflows.',
    image: 'integrate.png',
    status: 'Released',
    bullets: [],
  },
];

const pageStyle: CSSProperties = {
  minHeight: '100vh',
  position: 'relative',
  overflow: 'hidden'
};

const headerStyle: CSSProperties = {
  paddingTop: '40px',
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 24px',
  position: 'relative',
  zIndex: 1
};

const Home = () => {
  return (
    <Box style={pageStyle}>
      <Background />
      <NextSeo
        title='COHEZION'
        description='Community Data Platform Designed for Gaming Communities'
        canonical='https://cohezion.ai/'
        openGraph={{
          url: 'https://cohezion.ai/',
          title: 'COHEZION',
          description:
            'All-in-one community data aggregration platform designed for gaming communities',
          images: [
            {
              url: 'https://cohezion.ai/images/ez_avat.png',
              width: 800,
              height: 600,
              alt: 'cohezion avatar',
              type: 'image/png',
            },
          ],
          siteName: 'COEHZION',
        }}
      />

      <Box style={headerStyle}>
        <LandingPageHero />
        <TrustedBy />
        <TimeSteps />
        <BugReportsSection />
        <FeedbackSection />
        <CommunityAtScale />
        <StudioReviews />
        <MoreFeatures />
        <BottomCTA />
      </Box>

      <Footer />
    </Box>
  );
};

export default Home;
