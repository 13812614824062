import { Box, Container, Grid, GridItem, Heading, Text, Image, SimpleGrid, Flex } from '@chakra-ui/react';

const MoreFeatures = () => {
  const features = [
    {
      title: 'Structured data',
      description: 'Automated Level 2 customer support to ensure high fidelity reports',
      image: '/images/homeassets/structure-data-image.png'
    },
    {
      title: 'Feedback loops',
      description: 'Customizable status updates to keep your community engaged and informed',
      image: '/images/homeassets/feedback-loop-image.png'
    },
    {
      title: 'Management tool integrations',
      description: 'Seamless integrations across platforms',
      image: '/images/homeassets/management-tool-image.png'
    }
  ];

  return (
    <Box bg="transparent" py={20}>
      <Container maxW="container.xl">
        <Box textAlign="center" mb={16}>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
            fontWeight="medium"
            letterSpacing="wide"
            color="white"
            mb={4}
            fontFamily="'Chakra Petch', sans-serif"
          >
            The best tool for your gaming community
          </Heading>
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            color="whiteAlpha.800"
            maxW="3xl"
            mx="auto"
            opacity={0.9}
            letterSpacing="wide"
          >
            Cohezion equips you to effortlessly listen and react to your community
          </Text>
        </Box>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          {features.map((feature, index) => (
            <Flex 
              key={index} 
              bg="whiteAlpha.100" 
              borderRadius="xl" 
              p={6}
              direction="column"
              height="full"
              _hover={{ transform: 'scale(1.02)', transition: 'transform 0.2s' }}
            >
              <Box flex="1">
                <Heading
                  as="h3"
                  fontSize="xl"
                  color="white"
                  mb={2}
                  fontFamily="'Chakra Petch', sans-serif"
                  textAlign="center"
                >
                  {feature.title}
                </Heading>
                <Text color="whiteAlpha.800" mb={6} textAlign="center">
                  {feature.description}
                </Text>
              </Box>
              <Box mt="auto" display="flex" justifyContent="center">
                <Image
                  src={feature.image}
                  alt={feature.title}
                  w={index === 2 ? "70%" : "full"}
                  h="auto"
                  borderRadius="lg"
                />
              </Box>
            </Flex>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default MoreFeatures; 