import React from 'react';
import { Box, Image } from '@chakra-ui/react';

interface TabContentProps {
  activeTab: 'analytics' | 'bug-reports' | 'feedback';
}

const TabContent = ({ activeTab }: TabContentProps) => {
  const containerStyles = {
    bg: "rgba(20, 20, 35, 0.5)",
    backdropFilter: "blur(10px)",
    borderRadius: { base: "lg", md: "xl" },
    p: { base: 3, md: 4, lg: 6 },
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    minH: { base: "400px", md: "500px" }
  };

  const getImagePath = () => {
    switch (activeTab) {
      case 'analytics':
        return '/images/herodeck/analytics-slide.png';
      case 'bug-reports':
        return '/images/herodeck/bug-reports-slide.png';
      case 'feedback':
        return '/images/herodeck/feedback-slide.png';
      default:
        return '';
    }
  };

  return (
    <Box {...containerStyles}>
      <Image
        src={getImagePath()}
        alt={`${activeTab} slide`}
        width="100%"
        height="100%"
        objectFit="contain"
      />
    </Box>
  );
};

export default TabContent; 