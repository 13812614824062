import { Box, Button, Container, Flex, Heading, Image, Text, VStack, List, ListItem, ListIcon, Icon } from '@chakra-ui/react';
import { BsArrowRight, BsCheckLg } from 'react-icons/bs';

const FeedbackSection = () => {
  return (
    <Box py={16}>
      <Container maxW="8xl">
        <Flex
          gap={20}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
        >
          <VStack
            width={{ base: '100%', md: '50%' }}
            align="flex-start"
            spacing={8}
          >
            <Heading
              fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
              fontWeight="medium"
              letterSpacing="wide"
              color="white"
              lineHeight="1.2"
              fontFamily="'Chakra Petch', sans-serif"
            >
              Improve your game with insights from player feedback
            </Heading>

            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="whiteAlpha.800"
              lineHeight="tall"
              opacity={0.9}
              letterSpacing="wide"
            >
              Users don't always provide the most helpful or detailed feedback so our automated workflow steps in to uncover what they truly want by:
            </Text>

            <List spacing={4} color="whiteAlpha.900">
              <ListItem display="flex" alignItems="center">
                <ListIcon as={BsCheckLg} color="whiteAlpha.900" />
                Asking the user detailed questions to better understand their needs
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={BsCheckLg} color="whiteAlpha.900" />
                Generating a summary of the user's feedback for review
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={BsCheckLg} color="whiteAlpha.900" />
                Providing specific reasoning and details behind the user's requests
              </ListItem>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={BsCheckLg} color="whiteAlpha.900" />
                Sending the information to your Kanban board for review
              </ListItem>
            </List>

            <Button
              bg="#FF3CD5"
              color="black"
              size="lg"
              fontSize="md"
              px={6}
              py={7}
              borderRadius="xl"
              rightIcon={
                <Box
                  bg="black"
                  p={2}
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                >
                  <Icon as={BsArrowRight} color="white" boxSize={4} />
                </Box>
              }
              _hover={{
                bg: '#FF50DA'
              }}
              onClick={() => {
                window.open('https://app.cohezion.ai/sign-up', '_blank');
              }}
            >
              Start free trial
            </Button>
          </VStack>

          <Box
            width={{ base: '100%', md: '50%' }}
            minHeight={{ base: 'auto', md: '600px' }}
          >
            <Image
              src="/images/homeassets/feedback-frame.png"
              alt="Feedback Interface"
              width="100%"
              height="100%"
              objectFit="contain"
              borderRadius="xl"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default FeedbackSection; 