import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import TabContainer from './TabContainer';

const LandingPageHero = () => {
  return (
    <VStack
      align={'center'}
      spacing={{ base: 8, md: 10 }}
      direction={{ base: 'column', md: 'row' }}
      maxW={{ base: '90%', md: '80%', lg: '90%' }}
      margin={'0 auto'}
      py={12}
    >
      <Stack flex={1} spacing={{ base: 6, md: 8 }} color={'white'}>
        <HStack justify="center" spacing={2} mb={2}>
          <Icon as={FaDiscord} color="#B197FC" w={4} h={4} />
          <Text fontSize="sm" color="#B197FC">
            <a href="https://discord.com/blog/app-pitches-2023#heading-7" target="_blank" rel="noopener noreferrer">Honorable Mention at Discord's 2023 App Competition</a>
          </Text>
        </HStack>

        <Heading
          as="h1"
          fontSize={{ base: '3xl', md: '4xl', lg: '6xl' }}
          fontWeight="medium"
          letterSpacing="wide"
          textShadow="0 0 10px rgba(255, 255, 255, 0.3)"
          fontFamily="'Chakra Petch', sans-serif"
          textAlign="center"
          maxW="72rem"
          mx="auto"
        >
          The agentic platform to power the community-driven future of game development
        </Heading>

        <Text
          fontSize={'xl'}
          color={'white'}
          textAlign={'center'}
          maxW={'42rem'}
          mx={'auto'}
          mb={8}
          opacity={0.9}
          letterSpacing="wide"
        >
          Cohezion provides you with workflows and valuable insights to listen and react to your community
        </Text>

        <Stack
          direction={'row'}
          spacing={4}
          justify={'center'}
          align={'center'}
          mb={6}
        >
          <Button
            bg="#FF3CD5"
            color="black"
            size="lg"
            fontSize={'md'}
            px={6}
            py={7}
            borderRadius="xl"
            rightIcon={
              <Box
                bg="black"
                p={2}
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="24px"
                height="24px"
              >
                <Icon as={BsArrowRight} color="white" boxSize={4} />
              </Box>
            }
            _hover={{
              bg: '#FF50DA'
            }}
            onClick={() => {
              window.open('https://app.cohezion.ai/sign-up', '_blank');
            }}
          >
            Start free trial
          </Button>
          <Button
            variant="outline"
            size="lg"
            fontSize={'md'}
            px={6}
            py={7}
            color="white"
            borderColor="white"
            borderRadius="xl"
            _hover={{
              bg: 'whiteAlpha.100'
            }}
            onClick={() => {
              window.open(
                'https://calendar.google.com/calendar/u/0/appointments/AcZssZ30kwMH2usK38Aw_VRAwykd5xANTaLx8zME5b4=',
                '_blank'
              );
            }}
          >
            Book a demo
          </Button>
        </Stack>

        {/* New component for displaying content sections of Analytics, Bug Reports, and Feedback */}
        <Box mt={8} width="100%">
          <TabContainer />
        </Box>

        {/* Commenting out the video player from view, but leaving for reference in other pages */}
        {/* <Container maxWidth={'100%'} mt={16}>
          <Box position="relative" paddingBottom="56.25%" height="0" overflow="hidden" borderRadius="xl">
            <iframe 
              src="https://www.loom.com/embed/1b35f0a009a44bb291528a48a35e1911?sid=d8680347-4e25-4209-8162-dec3818976aa&hideEmbedTopBar=true"
              style={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
                borderRadius: 'inherit'
              }}
              allowFullScreen
            />
          </Box>
        </Container> */}
      </Stack>
    </VStack>
  );
};

export default LandingPageHero;
