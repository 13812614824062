import { Box, Button, Container, Heading, Text, Image, Icon } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';

const CommunityAtScale = () => {
  return (
    <Container maxW="container.xl" py={20}>
      <Box textAlign="center" mb={12}>
        <Heading
          as="h2"
          fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
          fontWeight="medium"
          letterSpacing="wide"
          color="white"
          mb={4}
          fontFamily="'Chakra Petch', sans-serif"
        >
          Understand your community at scale
        </Heading>
        <Text 
          fontSize={{ base: "lg", md: "xl" }} 
          color="whiteAlpha.800" 
          maxW="5xl" 
          mx="auto"
          opacity={0.9}
          letterSpacing="wide"
        >
          Reading every message is impossible. Our community analytics agent answers your questions and provides a diagnostic of your community, highlighting trending topics and key messages
        </Text>
        <Button
          bg="#FF3CD5"
          color="black"
          size="lg"
          fontSize="md"
          px={6}
          py={7}
          borderRadius="xl"
          mt={8}
          rightIcon={
            <Box
              bg="black"
              p={2}
              borderRadius="lg"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="24px"
              height="24px"
            >
              <Icon as={BsArrowRight} color="white" boxSize={4} />
            </Box>
          }
          _hover={{
            bg: '#FF50DA'
          }}
          onClick={() => {
            window.open('https://app.cohezion.ai/sign-up', '_blank');
          }}
        >
          Start free trial
        </Button>
      </Box>

      <Box
        position="relative"
        width="100%"
        maxW="1200px"
        mx="auto"
      >
        <Image
          src="/images/homeassets/community-frame.png"
          alt="Community analytics interface showing trending topics and message analysis"
          width="100%"
          height="auto"
          objectFit="contain"
        />
      </Box>
    </Container>
  );
};

export default CommunityAtScale; 