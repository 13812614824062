import React, { useState } from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';
import TabContent from './TabContent';

const TabContainer = () => {
  const [activeTab, setActiveTab] = useState<'analytics' | 'bug-reports' | 'feedback'>('analytics');

  return (
    <Box width="100%">
      <HStack spacing={4} mb={6} justify="center">
        <Button
          variant="ghost"
          color={activeTab === 'analytics' ? 'white' : 'whiteAlpha.600'}
          bg={activeTab === 'analytics' ? 'rgba(255, 255, 255, 0.1)' : 'transparent'}
          onClick={() => setActiveTab('analytics')}
          _hover={{ bg: 'whiteAlpha.100' }}
          fontSize="sm"
          px={6}
        >
          Community Analytics
        </Button>
        <Button
          variant="ghost"
          color={activeTab === 'bug-reports' ? 'white' : 'whiteAlpha.600'}
          bg={activeTab === 'bug-reports' ? 'rgba(255, 255, 255, 0.1)' : 'transparent'}
          onClick={() => setActiveTab('bug-reports')}
          _hover={{ bg: 'whiteAlpha.100' }}
          fontSize="sm"
          px={6}
        >
          Bug Reports
        </Button>
        <Button
          variant="ghost"
          color={activeTab === 'feedback' ? 'white' : 'whiteAlpha.600'}
          bg={activeTab === 'feedback' ? 'rgba(255, 255, 255, 0.1)' : 'transparent'}
          onClick={() => setActiveTab('feedback')}
          _hover={{ bg: 'whiteAlpha.100' }}
          fontSize="sm"
          px={6}
        >
          Feedback
        </Button>
      </HStack>

      <TabContent activeTab={activeTab} />
    </Box>
  );
};

export default TabContainer; 