import { Box, Container, Heading, Text, Button, Icon } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';

const BottomCTA = () => {
  return (
    <Box bg="transparent" py={20}>
      <Container maxW="container.2xl">
        <Box textAlign="center">
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
            fontWeight="medium"
            letterSpacing="wide"
            color="white"
            mb={4}
            lineHeight="1.2"
            fontFamily="'Chakra Petch', sans-serif"
          >
            Start building a stronger,
            more engaged community
          </Heading>
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            color="whiteAlpha.800"
            maxW="3xl"
            mx="auto"
            mb={8}
            opacity={0.9}
            letterSpacing="wide"
          >
            Start your 30-day free trial with Cohezion and unlock powerful insights to keep your players and community engaged
          </Text>
          
          <Button
            bg="#FF3CD5"
            color="black"
            size="lg"
            fontSize="md"
            px={6}
            py={7}
            borderRadius="xl"
            rightIcon={
              <Box
                bg="black"
                p={2}
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="24px"
                height="24px"
              >
                <Icon as={BsArrowRight} color="white" boxSize={4} />
              </Box>
            }
            _hover={{
              bg: '#FF50DA'
            }}
            onClick={() => {
              window.open('https://app.cohezion.ai/sign-up', '_blank');
            }}
          >
            Start free trial
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default BottomCTA; 