import { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useToken } from '@chakra-ui/react';

export function Background() {
  const svgRef = useRef<SVGSVGElement>(null);
  const [bgColor1, bgColor2] = useToken('colors', ['chakra.body.bg', 'purple.900']);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Clear existing content
    svg.selectAll('*').remove();

    // Set dimensions
    svg
      .attr('width', width)
      .attr('height', height)
      .style('position', 'absolute')
      .style('top', 0)
      .style('left', 0)
      .style('z-index', -1);

    // Create gradient
    const defs = svg.append('defs');
    const gradient = defs
      .append('linearGradient')
      .attr('id', 'background-gradient')
      .attr('x1', '%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '100%');

    gradient.append('stop').attr('offset', '0%').attr('stop-color', bgColor1);

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', bgColor2);

    // Create diagonal mask
    const mask = defs.append('mask').attr('id', 'diagonal-mask');

    // Create a gradient for the mask
    const maskGradient = defs
      .append('linearGradient')
      .attr('id', 'mask-gradient')
      .attr('x1', '100%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    // Adjust the gradient stops to create a centered diagonal band
    maskGradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', 'white')
      .attr('stop-opacity', 1);

    maskGradient
      .append('stop')
      .attr('offset', '25%')
      .attr('stop-color', 'white')
      .attr('stop-opacity', 0.2);

    maskGradient
      .append('stop')
      .attr('offset', '35.1%')
      .attr('stop-color', 'black')
      .attr('stop-opacity', 0.2);

    maskGradient
      .append('stop')
      .attr('offset', '65%')
      .attr('stop-color', 'black')
      .attr('stop-opacity', 1);

    maskGradient
      .append('stop')
      .attr('offset', '65.1%')
      .attr('stop-color', 'white')
      .attr('stop-opacity', 1);

    maskGradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', 'white')
      .attr('stop-opacity', 1);

    // Add rectangle with gradient to mask
    mask
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'url(#mask-gradient)');

    // Add background rectangle
    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'url(#background-gradient)');

    // Create grid
    const gridSize = 60;
    const numColumns = Math.ceil(width / gridSize);
    const numRows = Math.ceil(height / gridSize);

    // Create a group for grid lines
    const gridGroup = svg.append('g');

    // Draw vertical lines
    for (let i = 0; i <= numColumns; i++) {
      gridGroup
        .append('line')
        .attr('x1', i * gridSize)
        .attr('y1', 0)
        .attr('x2', i * gridSize)
        .attr('y2', height)
        .attr('stroke', 'white')
        .attr('stroke-width', 0.5)
        .style('mix-blend-mode', 'soft-light');
    }

    // Draw horizontal lines
    for (let i = 0; i <= numRows; i++) {
      gridGroup
        .append('line')
        .attr('x1', 0)
        .attr('y1', i * gridSize)
        .attr('x2', width)
        .attr('y2', i * gridSize)
        .attr('stroke', 'white')
        .attr('stroke-width', 0.5)
        .style('mix-blend-mode', 'soft-light');
    }

    // Apply the diagonal mask to the grid
    gridGroup.style('opacity', 0.15).attr('mask', 'url(#diagonal-mask)');

    // Handle resize
    const handleResize = () => {
      svg.attr('width', window.innerWidth).attr('height', window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [bgColor1, bgColor2]);

  return <svg ref={svgRef} />;
}
