import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useAuth, useSignIn, useUser } from '@clerk/nextjs';
import { SignOut, UserResource } from '@clerk/types';
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from '@chakra-ui/next-js';
import { useRouter } from 'next/router';
import useAmplitudeContext from 'components/Amplitude/useAmplitudeContext';

const Links = [
  { title: 'How it works', route: '/#timesteps', section: 'timesteps' },
  { title: 'Pricing', route: '/pricing' },
  { title: 'Benefits', route: '/#bugreports', section: 'bugreports' },
  { title: 'Reviews', route: '/#reviews', section: 'reviews' },
];

const ResourceLinks = [
  { 
    title: 'Developer Blog', 
    route: 'https://blog.cohezion.ai/'
  },
  { 
    title: 'Onboarding', 
    route: 'https://help.cohezion.ai/'
  },
];

interface navProps {
  route: string;
  title: string;
  section?: string;
}

const NavLink = (props: navProps) => {
  const router = useRouter();
  const isHomePage = router.pathname === '/';

  const handleClick = (e: React.MouseEvent) => {
    if (props.section && isHomePage) {
      e.preventDefault();
      const element = document.getElementById(props.section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (props.section && !isHomePage) {
      router.push(props.route);
    }
  };

  if (props.route.startsWith('http')) {
    return (
      <a
        href={props.route}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          textDecoration: 'none',
          color: 'white',
          padding: '0.5rem 1rem',
          fontSize: '1em',
          fontWeight: 'medium'
        }}
      >
        {props.title}
      </a>
    );
  }

  return (
    <Link
      href={props.route}
      id={`route_${props.route}`}
      fontSize={'md'}
      fontWeight={'medium'}
      color={'white'}
      _hover={{ textDecoration: 'none', opacity: 0.8 }}
      onClick={handleClick}
    >
      {props.title}
    </Link>
  );
};

const UserMenu = (user: UserResource, signOut: SignOut) => {
  return (
    <Box>
      <HStack>
        <Link id='profile_link' href={'https://app.cohezion.ai'}>
          Dashboard
        </Link>
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}
            id='avatar'
          >
            <Avatar src={user.profileImageUrl || ''} />
          </MenuButton>
          <MenuList alignItems={'center'}>
            <br />
            <Center>
              <Avatar size={'2xl'} src={user.profileImageUrl || ''} />
            </Center>
            <br />
            <Center>
              <p>{user.username}</p>
            </Center>
            <MenuItem>
              <Link id='profile_link' href={'/user'}>
                Profile
              </Link>
            </MenuItem>

            {/* <MenuItem>Contributions</MenuItem> */}
            <MenuDivider />
            <MenuItem id='signout' onClick={() => signOut()}>
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  );
};

export default function NavBar() {
  const { isLoaded, signOut } = useAuth();
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const bgIconColor = useColorModeValue('gray.700', 'gray.600');
  const router = useRouter();
  const { signIn } = useSignIn();
  // @ts-expect-error types hard
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const Login = () => {
    return (
      <HStack spacing={4}>
        <Button
          id='signin'
          variant="ghost"
          color="white"
          onClick={() => {
            router.push({
              pathname: '/sign-in',
              query: { redirect: router.asPath },
            });
          }}
        >
          Sign In
        </Button>
        <Button
          id='get-started'
          colorScheme="purple"
          onClick={() => {
            router.push('/sign-up');
          }}
        >
          Get started
        </Button>
      </HStack>
    );
  };

  let component;
  if (isLoaded && user) {
    component = UserMenu(user, signOut);
  } else component = Login();

  const logoSrc = '/images/COHEZION_LOGOS-03.png';

  const openExternalLink = (url: string) => {
    trackAmplitudeEvent('external_link_clicked', { url })
    window.open(url, '_blank');
  };

  return (
    <>
      <Box 
        px={4} 
        position="absolute" 
        width="100%" 
        zIndex={999}
      >
        <Container maxW={'8xl'}>
          <Flex h={16} alignItems={'center'}>
            {/* Logo */}
            <Box flex={{ base: "1", md: "1" }}>
              <LinkBox>
                <LinkOverlay href='/'>
                  <Image 
                    alt='COHEZION logo' 
                    src={logoSrc} 
                    width={{ base: '150px', md: '120px' }}
                    ml={{ base: '0', md: '0' }}
                  />
                </LinkOverlay>
              </LinkBox>
            </Box>

            {/* Centered Navigation - Desktop Only */}
            <Flex flex="2" justify="center" display={{ base: 'none', md: 'flex' }}>
              <HStack
                as={'nav'}
                spacing={8}
              >
                {Links.map((link, index) => (
                  <NavLink
                    key={'l' + index}
                    route={link.route}
                    title={link.title}
                    section={link.section}
                  />
                ))}
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    color="white"
                    rightIcon={<IoMdArrowDropdown />}
                    _hover={{ bg: 'whiteAlpha.200' }}
                    _active={{ bg: 'whiteAlpha.300' }}
                  >
                    Resources
                  </MenuButton>
                  <MenuList bg="rgba(20, 13, 37, 0.95)" borderColor="whiteAlpha.200">
                    {ResourceLinks.map((link, index) => (
                      <MenuItem
                        key={index}
                        as="a"
                        href={link.route}
                        target="_blank"
                        rel="noopener noreferrer"
                        bg="transparent"
                        color="white"
                        _hover={{ bg: 'whiteAlpha.200' }}
                      >
                        {link.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </HStack>
            </Flex>

            {/* Auth Buttons - Desktop Only */}
            <Box flex="1" display={{ base: 'none', md: 'flex' }} justifyContent="flex-end">
              <HStack spacing={4}>
                <Button
                  variant="ghost"
                  color="white"
                  fontSize="md"
                  onClick={() => {
                    router.push({
                      pathname: '/sign-in',
                      query: { redirect: router.asPath },
                    });
                  }}
                >
                  Sign in
                </Button>
                <Button
                  variant="outline"
                  color="white"
                  fontSize="md"
                  px={6}
                  borderColor="white"
                  borderRadius="xl"
                  _hover={{
                    bg: 'whiteAlpha.100'
                  }}
                  onClick={() => {
                    router.push('/sign-up');
                  }}
                >
                  Get started
                </Button>
              </HStack>
            </Box>

            {/* Mobile Menu Button */}
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              variant="ghost"
              color="white"
              onClick={isOpen ? onClose : onOpen}
              ml="auto"
            />
          </Flex>

          {/* Mobile Menu */}
          {isOpen ? (
            <Box 
              pb={4} 
              display={{ md: 'none' }}
              bg="rgba(20, 13, 37, 0.95)"
              backdropFilter="blur(10px)"
              borderRadius="xl"
              mt={4}
              p={6}
            >
              <Stack as={'nav'} spacing={6}>
                {Links.map((link, index) => (
                  <NavLink
                    key={'l' + index}
                    route={link.route}
                    title={link.title}
                    section={link.section}
                  />
                ))}
                
                {/* Mobile Resources Menu */}
                <Box>
                  <Text color="white" fontWeight="medium" mb={3}>Resources</Text>
                  <Stack pl={4} spacing={3}>
                    {ResourceLinks.map((link, index) => (
                      <Link
                        key={index}
                        href={link.route}
                        isExternal
                        color="whiteAlpha.900"
                        fontSize="md"
                        _hover={{ color: 'white' }}
                      >
                        {link.title}
                      </Link>
                    ))}
                  </Stack>
                </Box>

                {/* Mobile Auth Buttons */}
                <Divider borderColor="whiteAlpha.300" />
                <Stack spacing={4} pt={2}>
                  <Button
                    variant="ghost"
                    color="white"
                    fontSize="md"
                    justifyContent="flex-start"
                    px={0}
                    onClick={() => {
                      router.push({
                        pathname: '/sign-in',
                        query: { redirect: router.asPath },
                      });
                      onClose();
                    }}
                  >
                    Sign in
                  </Button>
                  <Button
                    bg="#FF3CD5"
                    color="black"
                    fontSize="md"
                    _hover={{
                      bg: '#FF50DA'
                    }}
                    onClick={() => {
                      router.push('/sign-up');
                      onClose();
                    }}
                  >
                    Get started
                  </Button>
                </Stack>
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  );
}
