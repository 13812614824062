import { Box, Container, Heading, Text, SimpleGrid, HStack, Avatar } from '@chakra-ui/react';

const StudioReviews = () => {
  const reviews = [
    {
      quote: "I would have bought this at PUBG no problem (and paid for a copy for each region / language!)",
      name: "Brian",
      title: "Chief Scientist, RocketScience.gg"
    },
    {
      quote: "Saves our team time and money, streamlining bug reports and community feedback",
      name: "Jay",
      title: "CEO, IndiGameBusiness"
    },
    {
      quote: "Game-specific platform to manage a game from inception to post-launch",
      name: "Slava",
      title: "CEO of Camlann"
    },
    {
      quote: "We're able to delay the hiring of a dedicated Community Manager with Cohezion",
      name: "Calum",
      title: "Production, Firestoke"
    }
  ];

  return (
    <Box bg="transparent" py={20} id="reviews">
      <Container maxW="container.xl">
        <Box textAlign="center" mb={16}>
          <Heading
            as="h2"
            fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
            fontWeight="medium"
            letterSpacing="wide"
            color="white"
            mb={4}
            fontFamily="'Chakra Petch', sans-serif"
          >
            Trusted and loved by gaming studios
          </Heading>
          {/* <Text
            fontSize={{ base: "lg", md: "xl" }}
            color="whiteAlpha.800"
            maxW="3xl"
            mx="auto"
            opacity={0.9}
            letterSpacing="wide"
          >
            Check out what gaming studios have to say about the impact that Cohezion has had on their communities!
          </Text> */}
        </Box>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
          {reviews.map((review, index) => (
            <Box
              key={index}
              bg="rgba(20, 13, 37, 0.6)"
              backdropFilter="blur(10px)"
              borderRadius="xl"
              p={8}
              position="relative"
            >
              <Text 
                color="white" 
                fontSize="lg" 
                mb={6}
                fontStyle="italic"
              >
                "{review.quote}"
              </Text>
              <HStack spacing={4}>
                <Avatar
                  size="md"
                  name={review.name}
                  bg="purple.400"
                />
                <Box>
                  <Text color="white" fontWeight="bold">
                    {review.name}
                  </Text>
                  <Text color="whiteAlpha.700">
                    {review.title}
                  </Text>
                </Box>
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default StudioReviews; 