import { Box, Container, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';

interface StepProps {
  icon: string;
  title: string;
  description: string;
}

const Step = ({ icon, title, description }: StepProps) => {
  return (
    <VStack spacing={4} align="center" flex={1}>
      <Box 
        bg="rgba(255, 255, 255, 0.1)" 
        p={4} 
        borderRadius="full"
        width="60px"
        height="60px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image src={`/images/timesteps/${icon}`} alt={title} width="24px" height="24px" />
      </Box>
      <Heading 
        as="h3" 
        fontSize="xl" 
        color="white" 
        textAlign="center"
        fontWeight="medium"
      >
        {title}
      </Heading>
      <Text 
        color="whiteAlpha.800" 
        textAlign="center" 
        fontSize="md"
        maxW="300px"
      >
        {description}
      </Text>
    </VStack>
  );
};

const TimeSteps = () => {
  const steps: StepProps[] = [
    {
      icon: 'step-icon1.png',
      title: 'Create an account',
      description: 'Sign up with Discord, Google, Twitch, or create an account using your email'
    },
    {
      icon: 'step-icon2.png',
      title: 'Integrate with platforms',
      description: 'Natively integrate Discord and project management tools for a seamless, secure workflow'
    },
    {
      icon: 'step-icon3.png',
      title: 'Gain actionable insights',
      description: 'Uncover insights and feedback needed to build an engaged community'
    }
  ];

  return (
    <Box py={16} id="timesteps">
      <Container maxW="container.xl">
        <VStack spacing={12}>
          <VStack spacing={4} textAlign="center" maxW="800px">
            <Heading 
              as="h2" 
              fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
              fontWeight="medium"
              letterSpacing="wide"
              color="white"
              mb={4}
              fontFamily="'Chakra Petch', sans-serif"
            >
              Prevent your dev and ops teams from losing valuable hours
            </Heading>
            <Text 
              color="whiteAlpha.800" 
              fontSize={{ base: "lg", md: "xl" }}
              opacity={0.9}
              letterSpacing="wide"
              maxW="3xl"
              mx="auto"
            >
              Cohezion streamlines workflows by aggregating bug reports and analyzing feedback, ensuring every input is actionable
            </Text>
          </VStack>

          <Flex 
            direction={{ base: 'column', md: 'row' }} 
            gap={{ base: 8, md: 4 }}
            width="100%"
            justify="space-between"
            align="stretch"
          >
            {steps.map((step, index) => (
              <Step key={index} {...step} />
            ))}
          </Flex>

          <Container maxWidth={'100%'} mt={8}>
            <Box position="relative" paddingBottom="56.25%" height="0" overflow="hidden" borderRadius="xl">
              <iframe 
                src="https://www.loom.com/embed/1b35f0a009a44bb291528a48a35e1911?sid=d8680347-4e25-4209-8162-dec3818976aa&hideEmbedTopBar=true"
                style={{ 
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  border: 0,
                  borderRadius: 'inherit'
                }}
                allowFullScreen
              />
            </Box>
          </Container>
        </VStack>
      </Container>
    </Box>
  );
};

export default TimeSteps; 