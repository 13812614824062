import { Box, Button, Container, Flex, Heading, Image, Text, VStack, Icon } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';

const BugReportsSection = () => {
  return (
    <Box py={16} id="bugreports">
      <Container maxW="8xl">
        <Flex
          gap={20}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
        >
          <Box
            width={{ base: '100%', md: '50%' }}
            minHeight={{ base: 'auto', md: '600px' }}
          >
            <Image
              src="/images/homeassets/bug-reports-frame.png"
              alt="Bug Reports Interface"
              width="100%"
              height="100%"
              objectFit="contain"
              borderRadius="xl"
            />
          </Box>

          <VStack
            width={{ base: '100%', md: '50%' }}
            align="flex-start"
            spacing={6}
          >
            <Heading
              fontSize={{ base: "3xl", md: "4xl", lg: "6xl" }}
              fontWeight="medium"
              letterSpacing="wide"
              color="white"
              lineHeight="1.2"
              fontFamily="'Chakra Petch', sans-serif"
            >
              Manage and automate bug reports at scale
            </Heading>

            <Text
              fontSize={{ base: "lg", md: "xl" }}
              color="whiteAlpha.800"
              lineHeight="tall"
              opacity={0.9}
              letterSpacing="wide"
            >
              Our bug reporting workflow interacts with users to collect valuable details and creates a comprehensive summary, shared both internally and on the community forum
            </Text>

            <Button
              bg="#FF3CD5"
              color="black"
              size="lg"
              fontSize="md"
              px={6}
              py={7}
              borderRadius="xl"
              rightIcon={
                <Box
                  bg="black"
                  p={2}
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="24px"
                  height="24px"
                >
                  <Icon as={BsArrowRight} color="white" boxSize={4} />
                </Box>
              }
              _hover={{
                bg: '#FF50DA'
              }}
              onClick={() => {
                window.open('https://app.cohezion.ai/sign-up', '_blank');
              }}
            >
              Start free trial
            </Button>
          </VStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default BugReportsSection; 